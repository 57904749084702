import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserDataService } from "../providers/user-data/user-data.service";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable()
export class AnonymousUserInterceptor implements HttpInterceptor {

  constructor(private userDataService: UserDataService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(environment.QpassConfiguration.apiUrl) >= 0) {
      let anonymousId = this.userDataService.getAnonynousUserId();
      if(anonymousId){
        req = req.clone({
          setHeaders: {
            'X-Anonymous-User-Token': anonymousId
          },
        });
      }
    }
    return next.handle(req);
  }
}